/* Add your CSS styles here */

:root {
  --text-color: black;
  --adamigo-background: #FBF3EA;
  --chat-main-background-color: white;
  --toggle-switch-background-color: #ccc;
}

[data-theme="dark"] {
  --text-color: white;
  --adamigo-background: #123;
  --chat-main-background-color: #333;
  --toggle-switch-background-color: #123;
}

.apply-background {
  background-color: var(--adamigo-background);
}

.chatbot-page-container{
  max-height: 100vh;
  overflow-y: hidden;
}

.chatbot-page {
  display: flex;
  flex-direction: column;
  margin: 0px 30px;
  min-height: 100vh;
}

.chatbot-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5% 1%;
}

.header-image {
  margin-left: 1em;
}

.chatbot-header-icon {
  width: 26px;
  height: 26px;
}

.avatar-image {
  width: 40px;
  height: 40px;
}

.chat-logo {
  width: 32px !important;
  height: 32px !important;
}

.chat-file {
  border-radius: 1rem;
  margin-left: 2.5em;
  height: 300px;
  width: fit-content;
}

.header-actions {
  display: flex;
  align-items: center;
}

.icon-action {
  margin-right: 10px;
  cursor: pointer;
}

// .modal-content{
//   background-color: var(--chat-main-background-color) !important;
// }

.settings-popup {
  position: absolute;
  top: 50px;
  right: 20px;
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.chat-container {
  background-color: var(--adamigo-background);
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding-top: 1em;
  padding-bottom: 1em;
}

.chat-sidebar {
  display: flex;
  flex-direction: column;
  min-width: 280px;
  overflow-y: hidden;
  flex: 0 0 auto; 
  max-height: calc(100vh - 6em);
  position: fixed; 
  top: 0; 
  left: -100%;
  transition: left 0.3s;
  z-index: 1;
}

.chat-sidebar.drawer-open { 
  position: sticky; 
  left: -100%;
  transition: left 0.3s;
}

.drawer-button {
  background: none;
  border: none;
  cursor: pointer;
  transition: transform 0.2s, color 0.2s;
}

.drawer-button:hover {
  transform: scale(1.2);
}

.drawer-button:focus {
  outline: none;
}

.chat-main {
  padding: 1rem;
  border-radius: 1.5rem;
  background-color: var(--chat-main-background-color);
  flex: 1;
  overflow-y: auto;
}

.css-1epurwo {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0px 10px !important;
}

.ads-model{
  max-height: 75vh;
  overflow-y: auto;
}

.ads-model-button {
  width: 75%;
}

.chat-sidebar ul {
  list-style: none;
  padding: 0;
}

.chat-sidebar li {
  cursor: pointer;
  padding: 0.5em;
  border-radius: 0.5em;
}

.chat-sidebar li.active {
  background-color: #F0DECB;
}

.pinned-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 0.3em 0.5em;
  margin-left: 0.5em;
  cursor: pointer;
}

.avatar-dropdown-menu {
  position: absolute;
  top: 100%; 
  right: 0;
  margin-top: 10px; 
  z-index: 1000;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.avatar-dropdown-menu::before {
  content: '';
  position: absolute;
  top: -10px;
  right: 10px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #fff transparent; /* Adjust color as needed */
}

.main-chat-container {
  flex: 1;
  max-height: calc(100vh - 6em);
  display: flex;
  flex-direction: column;
}

.chat-main2 {
  padding-top: 1rem;
  height: 100%;
  border-radius: 0.7em;
  overflow-x: hidden;
}

.chat-messages {
  list-style: none;
  margin: 0;
}


.message-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 20px;
  position: relative;
}

.user-message {
  font-size: medium;
  color: var(--text-color);
  // background-color: #d1d1a8;
  margin-left: 0.5em;
  padding: 0.25em 0.5em;
  border-radius: 1em;
  margin-bottom: 0.5em;
  text-align: left;
  max-width: fit-content;
  margin-right: 10px;
  flex-grow: 1;
}

.copy-button {
  visibility: hidden;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.message-container:hover .copy-button {
  visibility: visible;
}

ol{
  list-style: decimal;

  .markdown-li{
    margin-bottom: 1rem;
  }
}

.bot-message {
  background-color: #F5F5F4;
  font-size: medium;
  color: black;
  padding: 0.5em;
  border-radius: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 0.5em;
  text-align: left;
  list-style-position: inside
}

.file-label {
  padding: 0.5em 1em;
  border-radius: 0.5em;
  cursor: pointer;
}

.file-label:hover {
  color: #ed3e63;
}

.hover-red:hover{
  color: #ed3e63;
  cursor: pointer;
}

.file-input {
  display: none;
}

.message-input {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
  border: 1em;
  border-color: rgb(23, 13, 13);
}

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 3%; 
  cursor: pointer;
}

.message-textbox {
  width: 100%;
  justify-self: center;
  padding: 0 2em;
  color: black;
  outline: none;
  border: white;
  border-radius: 1.7em;
}

.image-upload-loader {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

.vertical-bar {
  position: fixed;
  background-color: #f5f5f5;
  border-radius: 0.8rem;
  box-shadow: 0 2px 6px #f5f5f5;
  z-index: 999;
}

.vertical-bar-option {
  padding: 8px 16px;
  border-radius: 0.8rem;
  cursor: pointer;
  white-space: nowrap;
}

.vertical-bar-option:hover {
  border-radius: 0.8rem;
  background-color: #FBF3EA;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.full-view {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.full-view img {
  max-width: 80%;
  max-height: 80%;
}

.full-view video {
  max-width: 80%;
  max-height: 80%;
}

// Custom styling for Scrollbars
// .scroll {
//   overflow-y: scroll;
// }

// ::-webkit-scrollbar {
//   width: 6px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   background: #f1f1f1;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: #888;
//   border-radius: 3px;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #555;
// }

.placeholder-messages-container{
  width: 90%;
}

.selected-image {
  margin: 1em 0.5em;
  max-height: 700px;
  width: 100%;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.toggle-switch {
  display: flex;
  align-items: center;
  width: 60px;
  height: 34px;
  background-color: var(--toggle-switch-background-color);
  border-radius: 34px;
  cursor: pointer;
}
.slider {
  width: 26px;
  height: 26px;
  background-color: white;
  margin-left: 4px;
  border-radius: 50%;
  transition: 0.4s;
}
.toggled {
  transform: translateX(26px);
}
.toggle-sidebar {
  display: none;
  cursor: pointer;
}

.chat-bubble {
  background-color: #ed3e63;
  padding:16px 28px;
  -webkit-border-radius: 20px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius: 20px;
  -moz-border-radius-bottomleft: 2px;
  border-radius: 20px;
  border-bottom-left-radius: 2px;
  display:inline-block;
}
.typing {
  align-items: center;
  display: flex;
  height: 17px;
}
.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #982b43;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}
.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}
.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}
.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}
.typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: #FBF3EA;
  }
  28% {
    transform: translateY(-7px);
    background-color: #fbf0ea;
  }
  44% {
    transform: translateY(0px);
    background-color: #f0eafb;
  }
}

@media (max-width: 768px) {

  .chatbot-page {
    margin: 0px 10px;
  }
  .chat-main{
    padding: 0.3rem 0.5rem 0 0.5rem;
  }

  .chat-container {
    flex-direction: column;
    padding: 0;
  }

  .chat-sidebar {
    width: fit-content;
    flex: 0 0 auto; 
    height: 100vh;
    max-height: 100vh;
    position: fixed; 
    top: 0; 
    left: -100%;
    transition: left 0.3s;
    z-index: 1;
    background-color: whitesmoke;
  }

  .chat-sidebar.drawer-open { 
    position: fixed;
  }

  .chatbot-header-icon {
    width: 18px;
    height: 18px;
  }

  .ads-model {
    height: 75vh;
  }

  .ads-model-button {
    width: 90%;
  }

  .avatar-image{
    width: 26px !important;
    height: 26px !important;
  }

  .toggle-sidebar {
    display: block;
  }

  .placeholder-messages-container{
    width: 98%;
  }

  .chat-sidebar.visible {
    left: 0; 
  }

  .chat-logo {
    display: none !important;
  }

  .chat-file {
    margin-left: 0.5em;
  }

  .main-chat-container {
    width: 100%;
    max-height: calc(100vh - 3em);
    padding: 0.5em;
  }

  .drawer-button {
    display: none;
  }
}
