/* PrivacyPolicy.css */

.privacy-policy-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px;
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: left;
}

h1 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}

h2 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

/* p {
  font-size: 12px;
  line-height: 1.6;
  margin-bottom: 8px;
} */

li{
  font-size: 12px;
  margin-bottom: 5px;
}

.privacy-policy-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
  margin-bottom: 15px;
}

.privacy-policy-table th,
.privacy-policy-table td {
  border: 1px solid #e0e0e0;
  padding: 12px;
  text-align: left;
}

/* Add a nice font */
body {
  font-family: 'Poppins', sans-serif;
}

span {
  font-size: 12px;
  font-weight: bold;
}

