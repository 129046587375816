body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'PT Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#dropdown-menu{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pt-sans {
  font-family: 'PT Sans', sans-serif;
}

.pt-serif {
  font-family: 'PT Serif Caption', serif;
}

/* Default layout - 3x3 grid */
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.align-kpis{
  text-align: center;
}

.header-container{
  width: 1100px;
}

.h1-font1{
  font-size: 2.5vw;
}
.h1-font2{
  font-size: 2.4vw;
}
.h1-font3{
  font-size: 1.8vw;
}
.h1-font4{
  font-size: 2vw;
}
.p-font1{
  width: 40vw;
  font-size: 1.25vw;
}
.p-font2{
  width: 35vw;
  font-size: 1.05vw;
}
.p-font3{
  width: 53vw;
  font-size: 1.05vw;
}
.p-font4{
  font-size: 1.3vw;
}
.weak-spots-max-width{
  width: 60vw;
}
.review-card-width{
  width: 350px;
}
.width-45{
  width: 45%;
}

.side-bar{
  align-self: start;
  background-color: #FBF3EA;
  top: 0;
  left: 0;
  flex-direction: column;
  position: sticky;
  height: 100vh;
  overflow-y: auto;
  flex: 0 0 auto;
}

h3{
  font-size: 18px !important;
  margin-bottom: 8px !important;
}

h4{
  font-size: 16px !important;
}

.main-results {
  flex-direction: column;
  background-color: whitesmoke;
  flex: 1 0 auto;
  overflow-x: auto;
}

.dropdown-toggle{
  background-color: white !important;
  color: grey !important;
  border-color: white;
  width: 100%;
}

.page_goal{
  text-align: left;
}

.landing-page-width {
  min-width: 800px;
  max-width: 1500px;
  margin-left: 48px;
  margin-right: 48px;
}

.survey-page-margins{
  margin: 0px 48px;
}

.demo-photo{
  max-width: 25rem;
  height: auto;
  filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.25));
}

.demo-photo2{
  width: 25%;
  height: auto;
  filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.25));
}

.half-width{
  max-width: 50%;
}

.header-image{
  width: 156px;
  height: 39px;
}

.image-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.terms-margin{
  margin: 0px 15px;
  text-decoration: none;
  color: grey;
}


/* Change to 1x9 layout for smaller screens */
@media (max-width: 900px) {
  .grid {
    grid-template-columns: 1fr;
    margin-left: auto;
  }
  .align-kpis{
    text-align: center;
  }
  .flex-container {
    flex-direction: column;
    align-items: center;
  }
  .header-container{
    display: none;
  }
  .side-bar{
    width: -webkit-fill-available;
    position: relative;
  }
  .main-results {
    margin-left: 0px;
  }
  .page_goal{
    text-align: left;
    margin-left: 10px;
  }
  .landing-page-width {
    min-width: 350px;
    max-width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
  .survey-page-margins {
    margin-left: 10px;
    margin-right: 10px;
  }
  .demo-photo2{
    width: 90%;
    height: auto;
    display: block;
  }
  .demo-photo{
    width: 90%;
    height: auto;
    justify-self: center;
    align-self: center;
  }
  .half-width{
    max-width: 100%;
  }
  .h1-font1{
    font-size: 5vw;
  }
  .h1-font2{
    font-size: 3.5vw;
  }
  .h1-font3{
    font-size: 3vw;
  }
  .h1-font4{
    font-size: 3.5vw;
  }
  .p-font1{
    width: auto;
    font-size: 2.5vw;
  }
  .p-font2{
    width: 70vw;
    font-size: 2.3vw;
  }
  .p-font3{
    width: 80vw;
    font-size: 2.3vw;
  }
  .weak-spots-max-width{
    width: auto;
  }
  .privacy-tag-font{
    font-size: 1.8vw;
  }
  .privacy-tag-container{
    width: 65vw;
  }
  .review-card-width{
    width: 300px;
  }
  .width-45{
    width: 90%;
  }
  .p-font4{
    font-size: 2.4vw;
  }
  .header-image{
    width: 100px;
    height: 25px;
  }
  .privacy-tag-width{
    width: 90%;
  }
  .image-container {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .image-container img {
    flex-basis: 26%;
    margin-bottom: 10px; 
  }
  .terms-margin{
    margin: 0px 7px;
  }
}

@media (min-width: 1500px) {
  h1{
    font-size: 40px !important;
  }
  /* p{
    font-size: 14px !important;
  } */
  .p-in-stat{
    font-size: 9px !important;
  }

}


.MuiInputBase-root{
  width: 300px;
  min-height: 40px;
  background-color: white !important;
}

/* progress bar */


:root {
  --line-border-fill:#ed3e63;
  --line-border-empty: #e0e0e0;
}


.progress-container::before {
  content: "";
  background: var(--line-border-empty);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 8px;
  width: 100%;
  z-index: 1;
  border-radius: 1rem;
}

.progress-2::before {
  content: "";
  background: var(--line-border-fill);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 4px;
  width: 33%;
  z-index: 1;
}

.progress-3::before {
  content: "";
  background: var(--line-border-fill);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 4px;
  width: 67%;
  z-index: 1;
}

.progress-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
  max-width: 100%;
  width: 350px;
  margin-left: auto;
  margin-right: auto;
}

.progress-container-copy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
  max-width: 100%;
  width: 435px;
  margin-left: auto;
  margin-right: auto;
}

.circle {
  background: #fff;
  color: #999;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid var(--line-border-empty);
  transition: .4s ease;
  z-index: 2;
}

.circle.active {
  border-color: var(--line-border-fill);
  cursor: pointer;
}

.loader-container {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.loader {
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}