:root {
  --text-color: black;
  --chat-main-background-color: white;
  --toggle-switch-background-color: #ccc;
}

[data-theme="dark"] {
  --text-color: white;
  --chat-main-background-color: #333;
  --toggle-switch-background-color: #123;
}

.settings-page-container{
  max-height: 100vh;
  overflow-y: hidden;
}

.settings-page {
  display: flex;
  flex-direction: column;
  margin: 0px 30px;
  min-height: 100vh;
}

.main-content-container {
  flex: 1;
  height: 88vh;
  display: flex;
  flex-direction: column;
}

.ads-model-2 {
  max-height: 85%;
  max-width: 500px;
  overflow-y: scroll;
}

.settings-sidebar {
  padding: 1em;
  min-width: 280px;
  top: 0;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  align-self: start;
  transition: left 0.3s;
}

.settings-sidebar ul {
  list-style: none;
  padding: 0;
}

.settings-sidebar li {
  cursor: pointer;
  padding: 0.5em;
  border-radius: 1rem;
  background-color: white;
}

.settings-sidebar li.active {
  background-color: #ed3e63;
}

.settings-main {
  border-radius: 0.7em;
  border-radius: 1.5rem;
  background-color: var(--chat-main-background-color);
  height: 94%;
}

.settings-sidebar.visible {
  left: 0;
}

@media (max-width: 768px) {
  .ads-model-2 {
    max-width: 300px;
    margin: 0 auto;
  }

  .settings-main {
    overflow-y: auto;
  }

  .settings-sidebar {
    width: fit-content;
    flex: 0 0 auto; 
    height: 100vh; 
    position: fixed; 
    top: 0; 
    left: -100%;
    transition: left 0.3s;
    z-index: 1;
    background-color: whitesmoke;
  }

  .settings-sidebar{
    position: fixed;
    left: -100%;
  }
  
  .main-content-container {
    width: 100%;
    padding: 1rem 0.5rem 1rem 0.5rem;
  }
}