:root {
  --background-color: #FBF3EA;
  --text-color: black;
}

[data-theme="dark"] {
  --background-color: #123;
  --text-color: white;
}

.App {
  min-height: 100vh;
  text-align: center;
  background-color: var(--background-color);
  color: var(--text-color);
}
